import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Homepage from "../pages/homepage";

export default () => {
	return (
		<Routes>
			<Route path="/" element={<Homepage />}></Route>
			<Route path="/downloads" element={<Navigate to="/snap" replace={true} />} />
			<Route path="/dashboard" element={<Navigate to="/dashboard/browse" replace={true} />} />
			<Route path="/turbo" element={<Navigate to="/dashboard/turbo" replace={true} />} />
		</Routes>
	);
};