import { Component } from "react";
import { ButtonProps } from "../../../types/components/button";
import styled from "styled-components";

const getVariant = (variant: string, type: string) => {
	if (variant === "white") {
		switch (type as string) {
		case "color":
			return "var(--button-white-color)";
			break;
		case "border":
			return "var(--button-white-border)";
			break;
		case "borderHover":
			return "var(--button-white-border-hover)";
			break;
		case "bg":
			return "var(--button-white-bg)";
			break;
		case "bgHover":
			return "var(--button-white-bg-hover)";
			break;
		}
	} else if (variant === "red") {
		switch (type as string) {
		case "color":
			return "var(--button-red-color)";
			break;
		case "border":
			return "var(--button-red-border)";
			break;
		case "borderHover":
			return "var(--button-red-border-hover)";
			break;
		case "bg":
			return "var(--button-red-bg)";
			break;
		case "bgHover":
			return "var(--button-red-bg-hover)";
			break;
		}
	} else if (variant === "green") {
		switch (type as string) {
		case "color":
			return "var(--button-green-color)";
			break;
		case "border":
			return "var(--button-green-border)";
			break;
		case "borderHover":
			return "var(--button-green-border-hover)";
			break;
		case "bg":
			return "var(--button-green-bg)";
			break;
		case "bgHover":
			return "var(--button-green-bg-hover)";
			break;
		}
	} else if (variant === "redOutline") {
		switch (type as string) {
		case "color":
			return "var(--button-redOutline-color)";
			break;
		case "border":
			return "var(--button-redOutline-border)";
			break;
		case "borderHover":
			return "var(--button-redOutline-border-hover)";
			break;
		case "bg":
			return "var(--button-redOutline-bg)";
			break;
		case "bgHover":
			return "var(--button-redOutline-bg-hover)";
			break;
		}
	} else if (variant === "grayOutline") {
		switch (type as string) {
		case "color":
			return "var(--button-grayOutline-color)";
			break;
		case "border":
			return "var(--button-grayOutline-border)";
			break;
		case "borderHover":
			return "var(--button-grayOutline-border-hover)";
			break;
		case "bg":
			return "var(--button-grayOutline-bg)";
			break;
		case "bgHover":
			return "var(--button-grayOutline-bg-hover)";
			break;
		}
	} else {
		switch (type as string) {
		case "color":
			return "var(--button-gray-color)";
			break;
		case "border":
			return "var(--button-gray-border)";
			break;
		case "borderHover":
			return "var(--button-gray-border-hover)";
			break;
		case "bg":
			return "var(--button-gray-bg)";
			break;
		case "bgHover":
			return "var(--button-gray-bg-hover)";
			break;
		}
	}
};

const SharedStyles = styled.button<ButtonProps>`
	position: relative;
	opacity: 1;
	cursor: pointer;
	transition: all 0.2s ease 0s;
	justify-content: center;
	align-items: center;
	outline: none;
	font-size: 1rem;
	border-radius: 5px;
	-webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const Default = styled(SharedStyles)`
	padding: 10px 40px;
	border: 2px solid ${props => getVariant(props.variant, "border")};
	color: ${props => getVariant(props.variant, "color")};
	position: relative;
	background-color: ${props => getVariant(props.variant, "bg")};
	:hover {
		background-color: ${props => getVariant(props.variant, "bgHover")};
		border: 2px solid ${props => getVariant(props.variant, "borderHover")};
	}
`;

const Medium = styled(SharedStyles)`
	padding: 8px 20px;
	border: 2px solid ${props => getVariant(props.variant, "border")};
	color: ${props => getVariant(props.variant, "color")};
	background-color: ${props => getVariant(props.variant, "bg")};
	:hover {
		background-color: ${props => getVariant(props.variant, "bgHover")};
		border: 2px solid ${props => getVariant(props.variant, "borderHover")};
	}
`;

const Small = styled(SharedStyles)`
	padding: 4px 20px;
	border: 2px solid ${props => getVariant(props.variant, "border")};
	color: ${props => getVariant(props.variant, "color")};
	position: relative;
	background-color: ${props => getVariant(props.variant, "bg")};
	:hover {
		background-color: ${props => getVariant(props.variant, "bgHover")};
		border: 2px solid ${props => getVariant(props.variant, "borderHover")};
	}
`;

const ExtraSmall = styled(SharedStyles)`
	padding: 4px 15px;
	border: 2px solid ${props => getVariant(props.variant, "border")};
	color: ${props => getVariant(props.variant, "color")};
	position: relative;
	background-color: ${props => getVariant(props.variant, "bg")};
	:hover {
		background-color: ${props => getVariant(props.variant, "bgHover")};
		border: 2px solid ${props => getVariant(props.variant, "borderHover")};
	}
`;

export default class Button extends Component {
	static Default = (props: any) => <Default {...props}>{props.children}</Default>;
	static Small = (props: any) => <Small {...props}>{props.children}</Small>;
	static Medium = (props: any) => <Medium {...props}>{props.children}</Medium>;
	static ExtraSmall = (props: any) => <ExtraSmall {...props}>{props.children}</ExtraSmall>;
}