import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./core/app";

Sentry.init({
	dsn: "https://745c0d3be7614379a4b46378fa989785@o526380.ingest.sentry.io/5708267",
	integrations: [ new Integrations.BrowserTracing() ],
	tracesSampleRate: 1.0,
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root"),
);