import { Component } from "react";
import styled from "styled-components";

const Primary = styled.input`
	height: 50px;
	padding: 20px;
	outline: none;
	text-align: left;
	border: 1px solid var(--input-gray-border);
	background-color: var(--input-gray-bg);
	color: white;
	margin: auto;
	border-radius: 8px;
	text-align: left;
	::placeholder {
		opacity: 0.5;
	}
	:focus {
		border: 1px solid var(--input-gray-border-focus);
	}
`;

export const PrimaryInput = (props: any) => {
	return (
		<Primary {...props}>
			{props.children}
		</Primary>
	);
};

export default class Input extends Component {
	static Primary = PrimaryInput;
}