import React from "react";
import { Button, Input, Select, Toggle } from "../../components/ui/elements";

export default () => {
	return (
		<div className="p-4 pt-4">
			<div className="text-blue-400 mb-2 font-bold text-2xl">TixteUI</div>
			<br />
			<div className="text-white mb-2 font-bold text-xl">Default</div>
			<Button.Default variant="white">white</Button.Default>
			<Button.Default variant="gray" className="ml-2">gray</Button.Default>
			<Button.Default variant="grayOutline" className="ml-2">grayOutline</Button.Default>
			<Button.Default variant="red" className="ml-2">red</Button.Default>
			<Button.Default variant="redOutline" className="ml-2">redOutline</Button.Default>
			<Button.Default variant="green" className="ml-2">green</Button.Default>
			<br />
			<br />
			<div className="text-white mb-2 font-bold text-xl">Medium</div>
			<Button.Medium variant="white">white</Button.Medium>
			<Button.Medium variant="gray" className="ml-2">gray</Button.Medium>
			<Button.Medium variant="grayOutline" className="ml-2">grayOutline</Button.Medium>
			<Button.Medium variant="red" className="ml-2">red</Button.Medium>
			<Button.Medium variant="redOutline" className="ml-2">redOutline</Button.Medium>
			<Button.Medium variant="green" className="ml-2">green</Button.Medium>
			<br />
			<br />
			<div className="text-white mb-2 font-bold text-xl">Small</div>
			<Button.Small variant="white">white</Button.Small>
			<Button.Small variant="gray" className="ml-2">gray</Button.Small>
			<Button.Small variant="grayOutline" className="ml-2">grayOutline</Button.Small>
			<Button.Small variant="red" className="ml-2">red</Button.Small>
			<Button.Small variant="redOutline" className="ml-2">redOutline</Button.Small>
			<Button.Small variant="green" className="ml-2">green</Button.Small>
			<br />
			<br />
			<div className="text-white mb-2 font-bold text-xl">ExtraSmall</div>
			<Button.ExtraSmall variant="white">white</Button.ExtraSmall>
			<Button.ExtraSmall variant="gray" className="ml-2">gray</Button.ExtraSmall>
			<Button.ExtraSmall variant="grayOutline" className="ml-2">grayOutline</Button.ExtraSmall>
			<Button.ExtraSmall variant="red" className="ml-2">red</Button.ExtraSmall>
			<Button.ExtraSmall variant="redOutline" className="ml-2">redOutline</Button.ExtraSmall>
			<Button.ExtraSmall variant="green" className="ml-2">green</Button.ExtraSmall>
			<br />
			<br />
			<div className="text-white mb-2 font-bold text-xl">Input</div>
			<Input.Primary placeholder="placeholder" className="w-1/3" />
			<br />
			<br />
			<div className="text-white mb-2 font-bold text-xl">Toggle (Default, Medium, Small)</div>
			<Toggle.Default onChange={(value) => console.log(value)}/>
			<Toggle.Medium />
			<Toggle.Small />
			<br />
			<br />
			<div className="grid grid-cols-3 gap-3">
				<div className="col-span-1">
					<div className="text-white mb-2 font-bold text-xl">Select</div>
					<Select.Primary options={[
						{ label: "discowd.com", value: 1 },
						{ label: "john.is-from.space", value: 2 },
						{ label: "testing.has.rocks", value: 3 },
						{ label: "super-duper-cool-domain.tixte.co", value: 4 },
					]} className="w-full inline-block" />
				</div>
				<div className="col-span-1">
					<div className="text-white mb-2 font-bold text-xl">Select w/ image</div>
					<Select.Primary options={[
						{ label: "Visa ending in 1111", value: 1, image: "https://us-east-1.tixte.net/assets/payment-providers/svg/visa.svg" },
						{ label: "Amex ending in 2222", value: 2, image: "https://us-east-1.tixte.net/assets/payment-providers/svg/amex.svg" },
						{ label: "Mastercard ending in 3333", value: 3, image: "https://us-east-1.tixte.net/assets/payment-providers/svg/mastercard.svg" },
						{ label: "Use a different payment method", value: 4, preventDefault: true },
					]} className="w-full inline-block" />
					<br />
				</div>
				<div className="col-span-1">
					<div className="text-white mb-2 font-bold text-xl">Select (disabled)</div>
					<Select.Primary
						options={[ { label: "Forced Option", value: 1 } ]}
						className="w-full inline-block"
						disabled={true}
					/>
				</div>
			</div>
			<br />
			<br />
		</div>
	);
};