import { Component, useState } from "react";
import { SelectProps, SelectOption } from "../../../types/components/select";
import convertToKey from "../../../utils/convertToKey";
import styled from "styled-components";
import { Popover } from "react-tiny-popover";
import * as Icon from "lucide-react";

const Primary = styled.div<SelectProps>`
	color: var(--select-primary-color);
	border-radius: ${props => props.active ? "0.375rem" : "0.375rem 0.375rem 0 0"};
	cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
	opacity: ${props => props.disabled ? "0.5" : "1"};
	position: relative;
	-webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const ActiveOption = styled.div<SelectProps>`
	background-color: var(--select-primary-bg);
	border: 1px solid var(--select-primary-border);
	border-radius: ${props => props.active ? "0.375rem 0.375rem 0 0" : "0.375rem"};
	min-height: 3rem;
	vertical-align: middle;
	padding: 0.6rem;
`;

const OptionList = styled.div`
	z-index: 999! important;
	vertical-align: middle;
	background-color: var(--select-primary-bg);
	border-top: 0px! important;
	border: 1px solid var(--select-primary-border);
	border-radius: 0 0 0.375rem 0.375rem;
	position: absolute;
	width: 100%;
`;

const Option = styled.div`
	min-height: 3rem;
	vertical-align: middle;
	padding: 0.6rem;
	:hover {
		background-color: var(--select-primary-bg-hover);
	}
`;

const OptionImage = styled.img`
	margin-right: 0.5rem;
	width: 2.5rem;
	vertical-align: middle;
	display: inline-block;
`;

const OptionLabel = styled.span`
	display: inline-block;
`;

const PrimaryInput = (props: any) => {
	const [isOpen, setOpen] = useState(false);
	const [selection, setSelection] = useState(
		props.options.filter(op => op.default)[0] ||
		props.options[0] ||
		{ label: "Choose an option" },
	);

	const handleChange = (option: object, preventDefault: boolean) => {
		if (preventDefault) {
			if (props.onClick && typeof props.onClick === "function") {
				props.onClick(option);
			}
		} else {
			setSelection(option);
			setOpen(!isOpen);
			if (props.onClick && typeof props.onClick === "function") {
				props.onClick(option);
			}
		}
	};

	return (
		<Primary active={isOpen} disabled={Boolean(props.disabled)} {...props}>
			<ActiveOption active={isOpen} onClick={() => setOpen(!isOpen && !props.disabled)}>
				{
					selection.image && (
						<OptionImage
							draggable={false}
							src={selection.image}
						/>
					)
				}
				<span>{selection.label}</span>
				<span className="float-right inline-block align-middle">
					{
						isOpen ? (
							<Icon.ChevronUp
								className="inline-block"
							/>
						) : (
							<Icon.ChevronDown
								className="inline-block"
							/>
						)
					}
				</span>
			</ActiveOption>
			{
				isOpen &&
				<OptionList>
					{
						props.options.filter(op => op !== selection).map((option: SelectOption) => (
							<Option
								onClick={() => handleChange(option, Boolean(option.preventDefault))}
								key={convertToKey(option.label)}
							>
								{
									option.image && (
										<OptionImage
											draggable={false}
											src={option.image}
										/>
									)
								}
								<OptionLabel>{option.label}</OptionLabel>
							</Option>
						))
					}
				</OptionList>
			}
		</Primary>
	);
};

export default class Dropdown extends Component {
	static Primary = (props: any) => <PrimaryInput {...props}></PrimaryInput>;
}