import { Component, useState } from "react";
import { ToggleProps } from "../../../types/components/toggle";
import styled from "styled-components";
import { Popover } from "react-tiny-popover";
import * as Icon from "lucide-react";

const Wrapper = styled.div<ToggleProps>`
	position: relative;
	display: inline-block;
	height: 1.7rem;
	cursor: pointer;
`;

const ToggleInner = styled.div<ToggleProps>`
	position: absolute;
	border-radius: 100%!important;
	vertical-align: middle!important;
	height: ${props => props.variant === "small" ? "1.2rem" : props.variant === "medium" ? "1.4rem" : "1.7rem"};
	width: ${props => props.variant === "small" ? "1.2rem" : props.variant === "medium" ? "1.4rem" : "1.7rem"};
    background-color: ${props => props.enabled ? "#02aeef" : "#b4b4b4"};
	transform: translateX(${props => props.enabled ? "100%" : "0%"});
	transition: all .15s ease 0s;
	top: 0.25rem;
	left: 0.25rem;
`;

const ToggleOuter = styled.div<ToggleProps>`
	display: inline-block;
	height: ${props => props.variant === "small" ? "1.7rem" : props.variant === "medium" ? "1.9rem" : "2.2rem"};
	width: ${props => props.variant === "small" ? "2.9rem" : props.variant === "medium" ? "3.3rem" : "3.9rem"};
	border: none;
	border-radius: 20px;
	outline: none;
	background-color: #3b3b3b;
	cursor: pointer;
`;

const ToggleComponent = (props: any) => {
	const [isEnabled, setEnabled] = useState(false);

	const handleToggle = (state: boolean) => {
		setEnabled(state);
	};
	
	return (
		<Wrapper>
			<ToggleOuter
				enabled={isEnabled}
				variant={props.variant}
				onClick={() => handleToggle(!isEnabled)}
			/>
			<ToggleInner
				enabled={isEnabled}
				variant={props.variant}
				onClick={() => handleToggle(!isEnabled)}
			/>
		</Wrapper>
	);
};

export default class Toggle extends Component {
	static Default = (props: any) => <ToggleComponent {...props} variant="default"></ToggleComponent>;
	static Medium = (props: any) => <ToggleComponent {...props} variant="medium"></ToggleComponent>;
	static Small = (props: any) => <ToggleComponent {...props} variant="small"></ToggleComponent>;
}