import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import "../styles/app.css";
import "../styles/colors.css";

export default () => {
	return (
		<Router>
			<Routes />
		</Router>
	);
};